.product-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-bottom: 1rem;
}

.product-image-container {
    height: auto;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-image {
    aspect-ratio: 10/12;
    object-fit: cover;
}

.product-info h3{
    font-size: 0.9rem;
    font-weight: 400;
    text-align: left;
    color: var(--primary-color);
    margin-top: .5rem;
}

.product-info a:hover{
    color: inherit;
}
.product-info span{
    font-size: .9rem;
}

.product_card-bottom {
    margin-bottom: 4rem;
}

.product_card-bottom .price {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    width: 150vw;
}

.product_card-bottom span i {
    font-size: 1.1rem;
    background: var(--primary-color);
    padding: .1rem;
    color: white;
    border-radius: 100%;
    position: relative;
}

@media only screen and (max-width: 768px) {
    .product-image-container {
        height: auto;
    }
    .product_card-bottom {
        margin-bottom: 1rem;
    }
    .product_card-bottom .price {
        font-size: 0.9rem;
    }
    .product-info h3{
        font-size: 0.8rem;
    }
}