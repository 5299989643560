.header{
    width: 100%;
    height: 90px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header.grotto-active {
  background-color: var(--primary-color);
  color: #fff;
}

.bg-container {
    align-items: center;
}



.nav-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo{
    display: flex;
    align-items: center;
    column-gap: 8px; 
}

.logo img{
    width: 10rem;
    height: auto;
    cursor: pointer;
}

.navigation {
    max-width: 40rem;
    width: 100%;
}

.menu {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    max-width: 50rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
}

.nav-item a{
    color: var(--primary-color);
    font-weight: 500;
    cursor: pointer;
    font-size: 1rem;
}

.nav-item {
    display: flex;
    align-items: center;
    color: var(--primary-color);
    font-weight: 500;
    cursor: pointer;
    font-size: 1rem;
}

.nav-item.grotto-active a{
    color: var(--nav-color) !important;
}

.nav-item.grotto-active i{
    color: var(--nav-color) !important;
}

.nav-icons {
    display: flex;
    align-items: center;
    justify-content: right;
    column-gap: 1rem; 
    width: 12rem;
}

.cart {
    cursor: pointer;
    color: var(--primary-color);
    position: relative;
    column-gap: .2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart.grotto-active {
    color: var(--nav-color);
}

.cart-icon {
    font-size: 18px;
}

.badge {
    width: 18px;
    height: 18px;
    content: '';
    background: var(--primary-color);
    color: var(--nav-color);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    font-weight: 600;
    z-index: 10;
}

.badge.grotto-active {
    background: var(--nav-color);
    color: var(--primary-color);
}

.nav-icons span i {
    font-size: 1.5rem;
    color: var(--primary-color);
    cursor: pointer;
}

.nav-icons.grotto-active span i {
    color: var(--nav-color);
}

.nav-icons .profile {
    position: relative;
    z-index: 9;
}

.nav-icons .profile .profile-actions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 150px;
    z-index: 10;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--card-bg-01);
    line-height: 2rem;
    display: none;
    cursor: pointer;
}

.ri-user-line {
    font-size: 1.5rem;
}

.ri-user-line.grotto-active {
    color: var(--nav-color);
}

.mobile-menu {
     font-size: 1.3rem ;
     color: var(--nav-color);
     display: none;
}

.nav-active {
    font-weight: 900 !important;
}

.retina-icon {
    display: none;
}

@media only screen and (max-width: 768px) {
    .bg-container {
        max-width: 700px;
    }
    .navigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .53);
        z-index: 9999;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease, visibility 0.3s ease;
    }

    .header{
        height: 80px;
        line-height: 70px;
    }

    .menu {
        position: absolute;
        top: 0;
        right: -250px;
        width: 250px;
        height: 100%;
        background: white;
        z-index: 10;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        transition: transform 0.3s ease;
    }

    .navigation.active-menu {
        visibility: visible; /* Display the navigation */
        opacity: 1; /* Fade in background */
    }

    .nav-item a {
        color: var(--primary-color);
    }

    .navigation.active-menu .menu {
        transform: translateX(-250px); /* Slide in the menu */
    }

    .mobile-menu {
        display: block;
        font-size: 1.5rem;
    }

    .logo .home-logo{
        width: 6.5rem;
        height: 6.5rem;
    }

    .logo h1 {
        font-size: 1rem;
    }

    .cart-icon {
        font-size: 1.2rem !important;
    }

    .nav-icons span img {
        width: 1.4rem;
        height: 1.4rem;
    }

    .retina-icon {
        display: block;
    }

    .logo img{
        width: auto;
        height: 1.3rem;
        cursor: pointer;
    }

    .nav-item.grotto-active a{
        color: var(--primary-color) !important;
    }
}