.product_accordion-item {
    margin-bottom: 15px; 
    border-bottom: 1px solid #000 !important;
    background-color: white !important; 
}

.product-accordion .accordion-button {
    background-color: white !important;
    padding-left: 0;
    padding-right: 0;
}

.product-accordion .accordion-body {
    background-color: white !important;
    padding-left: 0;
    padding-right: 0;
}

.product-accordion .accordion-title {
    font-size: 1.125rem;
    font-weight: 600;
}
.product-accordion .accordion-details {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    color: var(--primary-color);
}
  
.product-accordion .accordion-button:not(.collapsed) {
    color: #000;
}

.product-accordion .accordion-button:not(.collapsed)::after {
    
    color: #000;
}