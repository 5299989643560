.dashboard {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

/* Sidebar */
.dashboard-sidebar {
    background-color: #000;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align the items at the top */
    transition: width 0.3s ease;
    overflow: hidden;
}

.sidebar-logo {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-img {
    max-width: 50%; /* Adjust the logo size as needed */
    height: auto;
}

.dashboard-sidebar nav {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.dashboard-sidebar nav a {
    color: #ecf0f1;
    text-decoration: none;
    padding: 10px;
    margin: 5px 0;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.dashboard-sidebar nav a.active,
.dashboard-sidebar nav a:hover {
    background-color: var(--primary-hover-color);
}

.logout-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px;
    margin: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.logout-btn:hover {
    background-color: #c0392b;
}

/* Resizer */
.dashboard-resizer {
    width: 5px;
    cursor: ew-resize;
    background-color: #bdc3c7;
    height: 100%;
}

/* Main Content */
.dashboard-content {
    flex: 1;
    background-color: #ecf0f1;
    overflow: auto;
    padding: 20px;
}
