.my-accordion {
    margin-top: 20px; 
}
  
.accordion-item {
    margin-bottom: 15px; 
    border: 1px solid #EEEEEE;
    background-color: #EEEEEE; 
}

.accordion-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.accordion-details {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    color: var(--primary-color);
}

.my-accordion .accordion-button {
    background: #EEEEEE;
}

.accordion-button::before(.collapsed)::after {
    color: var(--primary-color) !important;
}

.my-accordion .accordion-button:focus {
    color: var(--primary-color);
    box-shadow: none;
}

@media only screen and (max-width: 760px) {
    .accordion-title {
        font-size: 1rem;
        font-weight: 600;
    }

    .accordion-details {
        font-size: .9rem;
        line-height: 1.5;
        font-weight: 400;
        color: var(--primary-color);
    }
}