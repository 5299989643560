.footer {
    padding-top: 60px;
    padding-bottom: 30px;
    background: var(--primary-color);
    position: relative;
    width: 100%;
    margin-top: auto;
}

.footer-text {
    line-height: 30px;
    color: rgba(255, 255, 255, 0.735);
}

.footer-logo img{
    margin-top: -1rem;
    width: 4.5rem;
    height: 4rem;
}

.footer_quick-links ul li {
    background: transparent !important;
}

.footer_quick-links ul li a {
    color: rgba(255, 255, 255, 0.735);
    transition: color 0.3s ease;
}
.footer_quick-links ul li a:hover {
    color: #ffffff !important; /* Force white on hover */
}


.quick_links-title {
    margin-bottom: 20px;
    color: #fff;
    font-size: 1.1rem;
}

.footer-contact li span,
.footer-contact li p {
    color: rgba(255, 255, 255, 0.735);
}

.footer-contact li span  {
    font-style: 1.2rem;
}

.footer-contact li p {
    font-size: .9rem;
}

.footer-copyright {
    color: rgba(255, 255, 255, 0.735);
    font-size: .9rem;
    margin-top: 50px;
    text-align: center ;
}

.quick_links-title {
    line-height: 2.5rem !important;
}