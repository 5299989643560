.hero-content {
    display: flex;
    padding: 2rem;
    justify-content: center;
}

@media only screen and (max-width: 1020px) {
    .hero-content {
        padding: 0;
        padding-top: 3rem;
    }
}

.hero-gif {
    width: 100%;
}

.animation-wrapper {
  overflow: hidden;
  width: 100vw;
  padding-top: 1rem;
}
.animation-wrapper .text-slider {
    overflow: none;
    white-space: nowrap; /* Prevent text wrapping */
    width: 100%; /* Adjust width based on content */
    animation: slideText 20s linear infinite forwards; /* Animation definition */
}

.animation-wrapper .text-slider-mirror {
    overflow: none;
    white-space: nowrap; /* Prevent text wrapping */
    width: 100%; /* Adjust width based on content */
    animation: slideTextReverse 20s linear infinite forwards; /* Animation definition */
    display: inline-block; /* Allow side-by-side positioning */
}

.text-content {
    font-family: 'Bodoni';
    font-size: 1.2rem;
    font-weight: 400;
    padding-left: 1rem;
    padding-right: 1rem;
    display: inline-block;
}

.mirror {
     transform: scaleX(-1);

}
  
@keyframes slideText {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%); /* Adjust % based on content width */
    }
}
  
@keyframes slideTextReverse {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0); /* Opposite direction for inner element */
    }
}

.section {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.title-container {
    display: flex;
    gap: 1rem;
}
.gallery-subtitile {
    font-size: 1.125rem;
}

.gallery {
    justify-content: left !important;
}

@media only screen and (max-width: 768px) {
    .text-content {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 575px) {
    .hero-content {
        padding-bottom: 3rem;
    }
}