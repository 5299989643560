/* Ensuring the form layout is clean and readable */
.manage-products {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the modal */
    width: 100%;
    max-width: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Add padding around the modal */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 600px; /* Increased width */
    max-width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Ensure scrolling if content overflows */
    margin-top: 20%;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"],
input[type="number"],
textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
}

textarea {
    resize: vertical;
}

button {
    padding: 10px 15px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

button:hover {
    background-color: var(--primary-hover-color);
}

button[type="button"] {
    background-color: #ccc;
}

button[type="button"]:hover {
    background-color: var(--primary-hover-color);
}

.products-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.products-table th,
.products-table td {
    padding: 12px;
    border: 1px solid #ddd;
}

.products-table th {
    text-align: left;
    background-color: #f4f4f4;
}

/* Styling for the checkboxes */
.form-group .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
}

.form-group .checkbox-group label {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 15px;
}

.form-group .checkbox-group input[type="checkbox"] {
    margin-right: 5px;
}

/* Styling for the image upload section */
.form-group input[type="file"] {
    padding: 5px;
    margin-top: 10px;
}

form button[type="submit"] {
    background-color: var(--primary-color);
}

form button[type="submit"]:hover {
    background-color: var(--primary-hover-color);
}

.size-check {
    width: 90px !important;
}