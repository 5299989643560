.padding-global {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.page-heading {
    font-weight: 600;
    font-size: 4.125rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;
}

.page-sub-heading {
    font-size: 1.125rem;
    line-height: 1.5;
    color: var(--primary-color);
    margin-bottom: 3rem;
}

.contact-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 4rem;
}

@media only screen and (max-width: 1020px) {
    .contact-box {
      display: flex;
      flex-direction: column; /* Stack columns on mobile */
    }
}

.contact-form {
    width: 100%;
}

.contact-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem
}

.contact-col {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}
@media only screen and (max-width: 1020px) {
    .contact-col {
      display: flex;
      flex-direction: column; /* Stack columns on mobile */
    }
}

.contact-item {
    gap: small; 
    width: 100%;
}

.contact-icon {
    font-size: 2rem;
}