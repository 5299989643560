.signup-wrapper {
    padding: 5rem !important;
}


.signup-wrapper img {
    width: 100px;
    height: 100px;
}

.signup-header h2 {
    margin-bottom: 3rem;
}

.signup-header p {
    margin-bottom: 3rem;
}

.signup-header span {
    opacity: 0.5;
}


.signup-wrapper Button {
    padding: 1rem;
    width: 100%;
    max-width: 50%;
    background: #000;
    justify-content: center;
    align-items: center;
    display: flex;
}

.date-input::placeholder {
  color: rgba(0, 0, 0, 0.5) !important; /* Adjust the opacity of the placeholder text */
}

.signup-wrapper p {
    text-align: center;
}
.signup-header {
    text-align: center;
}
.signup-header {
    text-align: center;
}

.required {
    text-align: right !important;
}

.form-group .radio-input {
    padding: 0 !important;
}

@media only screen and (max-width: 1020px) {
    .signup-wrapper {
        padding: 1rem !important;
    }
}

