.profile-header {
    background: var(--primary-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem;
}

.profile-header h2 {
    color: white;
    max-width: 20rem;
    text-align: center;
}

.profile-header span {
    color: white;
    max-width: 20rem;
    text-align: center;
}

.level-info {
    display: flex;
    flex-direction: row;
}

.level-info img {
    max-width: 10rem;
}

.level-details {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
}

.profile-bio h3 {
    padding: 1rem;
    text-align: right;
    width: 100%;
}

.profile-bio {
    margin: 0 !important;
    justify-content: start;
}

.coupon-section {
    padding-top: 5rem;
    padding-bottom: 7rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}
.coupon-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}