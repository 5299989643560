.video-background-container {
    position: absolute; /* Allows positioning of content relative to the video */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Ensure the video fills the container */
}

.video-background {
    position: absolute; /* Makes the video the background */
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 50%; /* Ensures the video covers the width */
    height: 50%; /* Ensures the video covers the h eight */
    object-fit: cover; /* Ensures the video fills the container */
    z-index: 1;
}

.retina {
    position: relative;
}

.retina-container {
    position: relative;
    z-index: 3; /* Retina container is on top of the video background */
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.content-container {
    position: relative; /* Content is positioned relative to the container */
    z-index: 1;
    justify-content: center;
    min-height: 80vh;
}

.hero_section {
    width: 100%;
    height: auto;
}

.draggable-product {
    position: absolute;
    z-index: 4; /* Draggable products are on top of everything */
    max-width: 15rem;
}

@media only screen and (max-width: 768px) {
    .draggable-product {
        max-width: 9rem;
    }

    .video-background {
        width: 100%; /* Ensures the video covers the width */
        height: auto; /* Ensures the video covers the h eight */
        object-fit: cover; /* Ensures the video fills the container */
        z-index: 1;
        bottom: -10;
    }
}