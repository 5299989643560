.form {
    max-width: 100%;
}

.input-block {
    margin-bottom: 1.5rem;
}
.form-label {
    font-weight: 400;
    margin-bottom: .5rem;
}

.form-input {
    height: auto;
    width: 100%;
    min-height: 2.75rem;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: .5rem;
    font-size: 1rem;
    line-height: 1.6;
}

.text-area {
    height: auto;
    min-height: 11.25rem;
    width: 100%;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: .5rem;
    font-size: 1rem;
    line-height: 1.6;
}

.form-btn {
    width: 100%;
    background-color: var(--primary-color);
    text-align: center;
    padding: .75rem;
    border: 1px solid var(--primary-color);
    color: white;
}