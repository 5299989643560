.modal-title {
    font-weight: 700;
    font-size: 2rem;
}

.modal-footer {
    flex-direction: column;
    padding-right: 1rem;
}

.subtotal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.cart-modal .modal-btn {
    color: white;
    background-color: var(--primary-color);
    width: 100%;
}

.modal-message {
    padding: 8rem;
    padding-top: 8rem;
    text-align: center;
}