/* ForgotPassword.css */
.forgot-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.forgot-password-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.forgot-password-container .form-group {
    margin-bottom: 15px;
}

.forgot-password-container label {
    display: block;
    margin-bottom: 5px;
}

.forgot-password-container input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.forgot-password-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.forgot-password-container button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}
