.manage-users {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.manage-users h1 {
    margin-bottom: 20px;
}

.controls {
    margin-bottom: 20px;
}

.controls button {
    margin-right: 10px;
    padding: 10px 15px;
    border: none;
    background-color: #000;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.controls button:hover {
    background-color: #282828;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

table th, table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

table th {
    background-color: #f4f4f4;
}

table tr:nth-child(even) {
    background-color: #f9f9f9;
}

table tr:hover {
    background-color: #f1f1f1;
}

.pagination-controls {
    margin-top: 20px;
}

.pagination-controls button {
    padding: 10px 15px;
    border: none;
    background-color: #000;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.pagination-controls button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}
